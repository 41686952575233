import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner1";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/cities/london/delta-hotels-by-marriott-london-armouries-chauffeur-service-london-limo-services-car-service-near-london.webp";
import one2 from "../../assets/images/hotels/cities/london/delta-hotels-by-marriott-london-armouries-private-chauffeur-service-london-car-service-from-london.webp";
import one3 from "../../assets/images/hotels/cities/london/airport-transfer-services-from-delta-hotels-by-marriott-london-armouries-best-price-car-services-in-london.webp";
import two1 from "../../assets/images/hotels/cities/london/limousine-services-from-towneplace-suites-by-marriott-london-car-service-24-hours-in-london-best-car-services-in-london.webp";
import two2 from "../../assets/images/hotels/cities/london/chauffeur-service-in-london-to-towneplace-suites-by-marriott-london-at-affordable-price-limo-car-service-downtown-london.webp";
import two3 from "../../assets/images/hotels/cities/london/hourly-chauffeur-service-from-towneplace-suites-by-marriott-london-affordable-car-services-near-london.webp";
import three1 from "../../assets/images/hotels/cities/london/car-service-from-london-to-hampton-inn-by-hilton-london-all-the-way-limo-services-london.webp";
import three2 from "../../assets/images/hotels/cities/london/executive-chauffeur-service-near-hampton-inn-by-hilton-london-comfortable-chauffeur-services-in-london.webp";
import three3 from "../../assets/images/hotels/cities/london/budget-car-hire-london-to-hampton-inn-by-hilton-london-affordable-price-luxury-service-limousines.webp";
import four1 from "../../assets/images/hotels/cities/london/car-hire-near-homewood-suites-by-hilton-london-ontario-business-class-limo-services.webp";
import four2 from "../../assets/images/hotels/cities/london/all-inclusive-limo-services-near-homewood-suites-by-hilton-london-ontario-limo-services-online-bookings.webp";
import four3 from "../../assets/images/hotels/cities/london/london-limo-services-to-homewood-suites-by-hilton-london-ontario-car-service-with-driver.webp";

const London = () => {
  const hero = {
    title: "London",
    description:
      "Whether for business or leisure travel in London, book AChauffeur in advance for a premium experience. Upgrade to our Personal Private Chauffeur Car Service with Sedans, SUVs, Vans, and Buses for a secure and comfortable journey.",
  };

  const desc = {
    title: "London",
    description:
      "London, located in southwestern Ontario, Canada, is a bustling city with a population exceeding 396,000, making it the 11th largest city in Canada and the sixth largest in the province. Known for its thriving economy, London is a hub for manufacturing, healthcare, and education, housing prestigious institutions such as the University of Western Ontario. The city also features cultural gems like the London Children's Museum, the London Art Gallery, and the Banting House National Historic Site.",
    website: "https://www.londontourism.ca",
    description2:
      "For an unparalleled travel experience, AChauffeur offers a top-tier Private Chauffeur Car Service in London, available 24/7. We provide all-inclusive flat rates and a diverse fleet of Sedans, SUVs, Vans, and Buses to cater to all your transportation needs. Whether you need to hire a car for seamless Airport Transfers, smooth Intercity Chauffeured Rides, or luxurious VIP Chauffeur Service, we've got you covered. Our premium Livery Car Service and elegant Limo from Toronto to London add a touch of sophistication to your journey. We also offer Event Limousine Service for special occasions and our Meet and Greet Service at Toronto Pearson International Airport ensures a warm welcome. Additional services include Private Car Service, Ground Transportation, Parcel Delivery Service, Winery Tour Service, and Charter Bus Tours. Enjoy a seamless, professional journey by booking through our convenient online reservation system.",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from london to Delta Hotels by Marriott London Armouries best car services from achauffeur",
        "Private chauffeur service from london city to Delta Hotels by Marriott London Armouries. luxury car service near london",
        "Find airport transfer services in london to Delta Hotels by Marriott London Armouries at an affordable price and best services",
      ],
      title: "Delta Hotels by Marriott London Armouries",
      location: "London, Ontario",
      distance: "190 m from center",
      map: "https://maps.google.com/maps?q=Delta%20Hotels%20by%20Marriott%20London%20Armouries&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from TownePlace Suites by Marriott London  to london city. Best car services from achauffeur",
        "Safe and secure chauffeur services in london to TownePlace Suites by Marriott London . All-inclusive car services downtown london",
        "Hourly chauffeur service from achauffeur to TownePlace Suites by Marriott London  from london canada at affordable price rates",
      ],
      title: "TownePlace Suites by Marriott London",
      location: "London, Ontario",
      distance: "7.3 km from center",
      map: "https://maps.google.com/maps?q=TownePlace%20Suites%20by%20Marriott%20London&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from london to Hampton Inn by Hilton London . Achauffeur Minimize your Stress and Frustration ",
        "Comfortable and executive chauffeur service to Hampton Inn by Hilton London  from london city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in london. Hire budget cars and limos to Hampton Inn by Hilton London . Luxury service available",
      ],
      title: "Hampton Inn by Hilton London",
      location: "London, Ontario",
      distance: "7.5 km from center",
      map: "https://maps.google.com/maps?q=Hampton%20Inn%20by%20Hilton%20London&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in london to Homewood Suites by Hilton London Ontario. Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from london city to Homewood Suites by Hilton London Ontario",
        "Do you need a car with driver? Achauffeur offers limo services with driver in london to Homewood Suites by Hilton London Ontario",
      ],
      title: "Homewood Suites by Hilton London Ontario",
      location: "London, Ontario",
      distance: "7.6 km from center",
      map: "https://maps.google.com/maps?q=Homewood%20Suites%20by%20Hilton%20London%20Ontario&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "London",
      end: "Windsor, Ontario",
      duration: "2h 3 min",
      distance: "191 Km",
      price: "211.72",
    },
    {
      start: "London",
      end: "Toronto, Ontario",
      duration: "2 hr 6 min",
      distance: "192 Km",
      price: "211.72",
    },
    {
      start: "London",
      end: "Detroit, Michigan, USA",
      duration: "2 hr 12 min",
      distance: "194 Km",
      price: "211.72",
    },
    {
      start: "London",
      end: "Mississauga, Ontario",
      duration: "1 hr 51 min",
      distance: "175 Km",
      price: "211.72",
    },
    {
      start: "London",
      end: "Niagara Falls, Ontario",
      duration: "2 hr 3 min",
      distance: "201 Km",
      price: "211.72",
    },
    {
      start: "London",
      end: "Markham, Ontario",
      duration: "2 hr 8 min",
      distance: "205 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Pre-Book An All-Inclusive Private Chauffeur Car In London.</title>
        <meta
          name="description"
          content="Your London Private Chauffeur Car Service. Pre-Book Professional All-Inclusive, Reliable, and Efficient Chauffeur Car Services in London. Best Price Guaranteed."
        />
        <meta
          name="keywords"
          content="Chauffeur Service in London, Airport Chauffeur Service in London, Private Chauffeur Service in London, Chauffeur Service From Toronto to London, London Chauffeur Service, Chauffeur Service to London, Airport Car Service in London, Airport Limo in London, Private Car Service in London, Hourly Chauffeur Service in London."
        />
        <link rel="canonical" href="/city/chauffeur-service-london" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default London;
