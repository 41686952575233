import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

const CityDesc = (props) => {
  return (
    <>
      <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 ">
        <div className="container">
          <h2>
           10% Off Long-Distance Car Service from  {props.desc.title}
          </h2>

          <div className="text-base mb-2">{props.desc.description}</div>
          <a
            href={props.desc.website}
            target="_blank"
            rel="noreferrer"
            className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-5 "
          >
            <div>Go to Website</div> <ArrowRightOutlined className="text-sm" />
          </a>
          <div className="text-base mb-2">{props.desc.description2}</div>
          <div className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base ">
            <Link
              to="/book-now-a-chauffeur-service"
              className="text-black hover:text-pri-500"
            >
              Book for your Trip
            </Link>
            <ArrowRightOutlined className="text-sm" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CityDesc;
